import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { businesses } from '../../state';

// eslint-disable-next-line react/prop-types
const BackgroundBlock = ({ height = 170 }) => {
  const business = useSelector(businesses.selectors.selectBusiness);
  const useStyles = makeStyles(() => ({
    fsBackground: {
      background: business?.brandColors?.backgroundColor || '#e4f9fc',
      minHeight: height,
      maxHeight: height,
      width: '100%',
      position: 'absolute',
      left: 0,
      zIndex: -1,
    },
  }));
  const classes = useStyles();

  return <Box className={classes.fsBackground} />;
};

export default BackgroundBlock;

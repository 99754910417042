import React from 'react';
import { Router } from '@reach/router';
import GiftCardList from '../components/routes/GiftCard/GiftCardList';

const CardPage = () => {
  return (
    <Router basepath="cards">
      <GiftCardList path=":businessId" />
    </Router>
  );
};

export default CardPage;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Container, Divider, Grid, Typography } from '@material-ui/core';
import { useParams } from '@reach/router';
import { businesses, giftCards } from '../../../state';

import SEO from '../../seo';
import { BusinessHeader, BusinessFooter } from '../../layouts';
import GiftCardItem from './GiftCardItem';
import BackgroundBlock from '../../custom/BackgroundBlock';

const GiftCardList = () => {
  const { i18n } = useTranslation();
  const { businessId } = useParams();
  const dispatch = useDispatch();
  const business = useSelector(businesses.selectors.selectBusiness);
  const { data: giftCardsList } = useSelector(
    giftCards.selectors.selectGiftCards
  );
  const { t } = useTranslation();
  useEffect(() => {
    dispatch(giftCards.actions.giftCardsList(businessId));
    dispatch(businesses.actions.fetchMerchant(businessId));
  }, []);

  useEffect(() => {
    if (business && business.culture) {
      i18n.changeLanguage(business.culture);
    }
  }, [business]);

  const { logoUrl, website, brandColors } = business;
  return (
    <>
      <SEO title="Gift Cards" />
      <BusinessHeader
        logoUrl={logoUrl}
        merchantUrl={website}
        merchantPintunaUrl={website}
        bgcolor={business.brandColors?.headerColor || '#eee'}
      />
      <Divider />
      <Container
        maxWidth="lg"
        disableGutters
        bgcolor={business.brandColors?.backgroundColor || '#eee'}
        style={{ minHeight: 'calc(100vh - 133px)' }}
      >
        <BackgroundBlock height={150} />
        <Grid
          container
          direction="row"
          justifyContent="center"
          background={brandColors?.backgroundColor}
        >
          <Grid item style={{ marginBottom: 48 }} xs={12}>
            <Typography
              variant="h3"
              style={{
                marginTop: 32,
                marginBottom: 32,
                fontSize: 40,
                textAlign: 'center',
              }}
            >
              {t('giftCards')}
            </Typography>
          </Grid>
          <Grid
            container
            item
            spacing={3}
            style={{ marginTop: 10 }}
            md={10}
            xs={12}
            justifyContent="center"
          >
            {giftCardsList.map((item) => (
              <Grid item md={3} sm={6} xs={12} key={item.id}>
                <GiftCardItem giftCard={item} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Container>
      <BusinessFooter bgcolor={business.brandColors?.footerColor} />
    </>
  );
};

export default GiftCardList;

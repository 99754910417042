/* eslint-disable react/prop-types */
import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import { Box, Button, Typography } from '@material-ui/core';
import { Link } from 'gatsby';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ShowMoreText from 'react-show-more-text';
import i18n from 'i18next';
import { useEffect, useState } from 'react';
import { businesses } from '../../../state';

const GiftCardItem = ({ giftCard }) => {
  const { currency } =
    useSelector(businesses.selectors.selectBusiness) || 'USD';
  const [expand, setExpand] = React.useState(false);
  const [description, setDescription] = useState('');

  useEffect(() => {
    const currentDescription =
      giftCard.termAndConditions?.find((te) => te.culture === i18n.language)
        ?.description ||
      giftCard.termAndConditions?.find((te) => te.culture === 'en-US')
        ?.description ||
      giftCard.description;

    setDescription(currentDescription);
  }, [i18n.language, giftCard.termAndConditions, giftCard.description]);

  const { t } = useTranslation();
  return (
    <Card
      raised
      sx={{
        borderRadius: '10px',
        border: '1px solid rgba(33, 37, 41, .3)',
        boxShadow: 2,
      }}
    >
      <CardActionArea
        sx={{
          minHeight: 360,
          maxHeight: expand ? 'none' : 360,
          height: expand ? 'auto' : 360,
          padding: '10px',
        }}
      >
        <Box mb={1}>
          <CardMedia
            component="img"
            height="140"
            width="100%"
            image={giftCard.imageUrl}
            alt={giftCard.name}
          />
        </Box>
        <CardContent
          sx={{
            minHeight: 100,
            padding: 0,
            marginBottom: 2,
            position: 'relative',
          }}
        >
          <Typography
            gutterBottom
            variant="subtitle1"
            component="div"
            style={{ fontWeight: 500 }}
          >
            {giftCard.name}
          </Typography>
          <Typography gutterBottom>
            <ShowMoreText
              lines={3}
              more={t('showMore')}
              less={t('showLess')}
              expanded={expand}
              onClick={() => setExpand(!expand)}
            >
              {description}
            </ShowMoreText>
          </Typography>
        </CardContent>
        <Box style={{ float: 'right', minWidth: 120 }}>
          <Typography
            gutterBottom
            variant="subtitle1"
            style={{
              fontWeight: 700,
              marginLeft: 5,
            }}
          >
            {Number(giftCard.discounts[1]) > 0 ? `${t('from')} ` : ''}
            {t('cardValue', {
              amount: giftCard.discounts[0].toFixed(2),
              formatParams: {
                amount: { currency },
              },
            })}
          </Typography>
          <Box style={{ float: 'right' }}>
            <Button
              variant="contained"
              size="small"
              color="primary"
              component={Link}
              to={`/card/${giftCard.id}`}
              style={{
                paddingLeft: 16,
                paddingRight: 16,
                borderRadius: 16,
                minWidth: '120px',
                height: '36px',
              }}
            >
              {t('buy')}
            </Button>
          </Box>
        </Box>
      </CardActionArea>
    </Card>
  );
};

export default GiftCardItem;
